import { BookingSlotType } from "./booking-slot-type";
import { PatientLabel } from "./patient-label";
import { BookingSlotAvailability } from "src/app/models/booking-slot-availabilities";
import { Establishment } from "./establishment";
import { Speciality } from "./speciality";
import { DoctorMedicines } from "./doctor-medicines";
import { DoctorImage } from "./doctor-image";
import { ClinicResource } from "./clinic_resource";
import { Department } from "./department";
import { ClinicResourceAvailability } from "./clinic_resource_availability";
import { AvailableDay } from "./available_day";

export class Doctor {
  id: string;
  email: string;
  photo: string;
  title: string;
  first_name: string;
  last_name: string;
  name: string;
  first_name_ar: string;
  last_name_ar: string;
  coordinates: number[];
  specialities: Array<Speciality>;
  ordered_specialities_ids: Array<string>;
  phone_number: string;
  cellphone_number: string;
  full_address: string;
  address_neighborhood: string;
  city: string;
  profile_url: string;
  degrees: string;
  presentation: string;
  degrees_ar: string;
  presentation_ar: string;
  global_rating: string;
  profile_completeness: string;
  time_zone: string;
  utc_offset: number;
  average_booking_duration: number;
  locale: string;
  country: string;
  google_token: boolean;
  booking_slot_types: Array<BookingSlotType>;
  patient_labels: Array<PatientLabel>;
  booking_slot_availabilities: Array<BookingSlotAvailability>;
  available_days_for_clinic_resources: ClinicResourceAvailability[];
  available_days: AvailableDay[];
  prescription_title: string;
  average_time_interval: number;
  video_consultation_enabled: boolean;
  is_premium: boolean;
  address: string;
  postal_code: string;
  address_additional_1: string;
  address_additional_2: string;
  phone_number_secondary: string;
  website: string;
  gender: string;
  started_practice: string;
  ice: string;
  password: string;
  video_consultation_price: string;
  rib: string;
  establishments: Array<Establishment>;
  photo_1: DoctorImage;
  payment_state: string;
  opening_hour: number;
  closing_hour: number;
  premium_expiration_date: Date;
  city_id: string;
  language_ids: string;
  home_visit_info: string;
  plan_name: string;
  plan_type: string;
  act_ids: string;
  address_ar: string;
  address_neighborhood_ar: string;
  address_additional_1_ar: string;
  address_additional_2_ar: string;
  sms_balance: number;
  mind_partner: boolean;
  medicines: DoctorMedicines[];
  images: DoctorImage[];
  clinic: boolean;
  clinic_resources: ClinicResource[];
  departments?: Department[];
  intercom_hash: string;
  short_link?: string;
  in_paid_countries: boolean;
  settings: {
    is_prescription_header_footer_disabled: boolean;
  };
}
