<div class="categories-dashboard mt-3" [ngClass]="{'is-collapsed': isPortletCollapse}">
  <header class="dashboard-header">
    <div class="header-content">
      <h2 class="title">
        {{ "connect.calendar.appointment_categories" | translate }}
      </h2>
      
      <div class="header-actions">
        <button class="edit-btn" (click)="navigateToLink('/booking-availabilities/types')">
          <i class="fas fa-edit"></i>
          {{ "connect.calendar.edit_appointment_categories" | translate }}
        </button>
        
        <button class="collapse-btn" (click)="togglePortlet(isPortletCollapse ? 'down' : 'up')">
          <i class="fas fa-angle-{{ isPortletCollapse ? 'up' : 'down' }}"></i>
        </button>
      </div>
    </div>
  </header>

  <div class="categories-container" *ngIf="!isPortletCollapse">
    <div class="categories-list">
      <div class="category-chip" 
           *ngFor="let type of doctorBookingTypes"
           [style.background-color]="type.color">
        <span class="category-name">{{ type.name | titlecase }}</span>
      </div>
    </div>
  </div>
</div>