import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO } from 'ngx-intl-tel-input';
import { PlanTypeFilter } from 'src/app/enums/planTypeFilter.enum';
import { PhoneValidationHelper } from 'src/app/helpers/phone_validation_helper';
import { Doctor } from 'src/app/models/doctor';
import { Plan } from 'src/app/models/plan';
import { DoctorService } from 'src/app/services/doctor.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-x',
  templateUrl: './payment-x.component.html',
  styleUrls: ['./payment-x.component.scss'],
  providers: [DecimalPipe]
})
export class PaymentXComponent implements OnInit {
  preferredCountries: CountryISO[] = [
    CountryISO.Morocco,
    CountryISO.Algeria,
    CountryISO.Tunisia,
  ];
  currencies = {
    [CountryISO.Morocco]: 'MAD',
    [CountryISO.Tunisia]: 'TND',
    [CountryISO.Algeria]: 'DZD'
  };
  selectedCurrency = "";
  country: string = "";
  currentDoctor: Doctor;
  shouldDisplayConnectPlan=true;
  validPhoneNumber = false;
  subscriptionForm: FormGroup;
  plans: Plan[] = [];
  exchangeRate: number;
  connectPlusPlans: Plan[] = [];
  cmisActionSLK: string = "";
  keys: any;
  cmis: any;

  constructor(
    private fb: FormBuilder,
    private phoneValidationHelper:PhoneValidationHelper,
    private doctorService: DoctorService,
    private paymentService: PaymentService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.getDoctorInformation();
  }

  initializeForm(doctor: Doctor = null, plans: Plan[] = []) {
    this.subscriptionForm = this.fb.group({
      plan_id: [plans?.[0]?.id, Validators.required],
      last_name: [doctor?.first_name, Validators.required],
      first_name: [doctor?.last_name, Validators.required],
      email: [doctor?.email, [Validators.required, Validators.email]],
      phone_number: [doctor?.phone_number, Validators.required],
      adresse: [doctor?.address, Validators.required],
      cgv: [false, Validators.requiredTrue]
    });
  }

  getDoctorInformation() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) =>
      {
        if (currentDoctor)
        {
          this.currentDoctor = currentDoctor;
          this.country = currentDoctor.country;
          console.log(this.country);
          this.selectedCurrency = this.getCurrencySymbol(this.currencies[this.country.toLowerCase() as CountryISO]);
          console.log(this.selectedCurrency);
          this.getPlans(this.country);
        }
      });
  }

  getPlans(country)
  {
    this.paymentService.getPlans(PlanTypeFilter.SUBSCRIPTION, country).subscribe((plans: Plan[]) =>
    {
      if (plans?.length == 0) return;
      this.plans = plans;
      this.connectPlusPlans = this.plans.filter(plan => plan.type == 'connect_plus');
      this.fillForm(this.currentDoctor, this.plans);
    });
  }

  fillForm(doctor: Doctor, plans: Plan[] = [])
  {
    // if no plans, show message no plans for this country
    this.initializeForm(doctor, plans);    
    const phoneControl = this.subscriptionForm.get('phone_number');
    phoneControl?.valueChanges.subscribe((phoneElement) =>
    {
      this.validPhoneNumber = this.phoneValidationHelper.validatePhoneNumber(phoneControl, phoneElement?.countryCode);
      this.phoneValidationHelper.setPhoneNumberErrors(phoneControl, this.validPhoneNumber);
    });
  }

  isConnectPlusPlan(plan: Plan)
  {
    return plan.type == 'connect_plus';
  }

  getCurrencySymbol(currencyCode: string): string {
    const currencySymbols = {
      TND: 'TD',
      MAD: 'MAD',
      DZD: 'DA'
    };
    return currencySymbols[currencyCode] || currencyCode;
  }

  getDynamicConnectPlusPlansMessage() {
    if (this.connectPlusPlans.length == 0) return '';
    
    const priceParts = this.connectPlusPlans.map(plan => {
      const formattedPrice = this.formatPrice(
        this.getTotalPrice(plan.price, plan.exchange_rate)
      );
      
      return this.translate.instant('connect.payments.connect_plus.price_format', {
        price: formattedPrice,
        currency: this.selectedCurrency,
        duration: plan.duration
      });
    });
  
    return this.translate.instant('connect.payments.connect_plus.message', {
      prices: priceParts.join(' / ')
    });
  }
  
  getPricePerMonth(plan: Plan): string
  {
    return this.formatPrice(this.getTotalPrice(plan.price, plan.exchange_rate) / plan.duration);
  }
  
  getTotalPrice(price: number, exchangeRate: number): number
  {
    return price * exchangeRate;
  }
  
  formatPrice(price: number): string
  {
    return price % 1 === 0 ? price.toFixed(0) : price.toFixed(2);
  }

  onPhoneNumberChange() {
    this.validPhoneNumber = this.subscriptionForm.controls.phone_number.status === "VALID";
  }

  getPlanName(plan: Plan)
  {
    return plan.type?.replace(/_/g, ' ')?.replace(/\bplus\b/i, '+')?.replace(/^\w/, c => c.toUpperCase()) || plan.type;
  }

  onSubmit() {
    if (!this.subscriptionForm.valid) return;
    const selectedPlan = this.plans.find(plan => plan.id == this.subscriptionForm.get('plan_id')?.value);    
    const formData = {
      ...this.subscriptionForm.value,
      phone_number: this.subscriptionForm.controls.phone_number.value?.e164Number,
      payment_method : 'MTC',
      type: selectedPlan.type
    };
    this.paymentService.create(formData)
    .subscribe((data: any) =>
    {
      this.cmis = data;
      this.keys = Object.keys(this.cmis.params_hash);
      
      setTimeout(() => {
        document.forms["paymentForm"].submit()
      }, 0);
    });
  }
}
