import { NgModule, inject } from "@angular/core";
import { CanActivateFn, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";

import { AppointmentsTableComponent } from "./components/appointments-table/appointments-table.component";
import { DoctorCommentsComponent } from "./components/doctor-comments/doctor-comments.component";
import { SmsDetailsComponent } from "./components/sms-details/sms-details.component";
import { DoctorSettingsComponent } from "./components/doctor-settings/doctor-settings.component";
import { PrescriptionTableComponent } from "./components/prescription-table/prescription-table.component";
import { TemplateComponent } from "./components/template/template.component";
import { BookingAvailabilityComponent } from "./components/booking-availability/booking-availability.component";
import { PatientLabelComponent } from "./components/patient-label/patient-label.component";
import { DoctorAvailabilitiesNewComponent } from "./components/doctor-availabilities-new/doctor-availabilities-new.component";
import { DoctorPrescriptionHeaderComponent } from "./components/prescription-table/doctor-prescription-header/doctor-prescription-header.component";
import { MedicalRecordPrintComponent } from "./components/medical-record-print/medical-record-print.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { InvoiceRecordTableComponent } from "./components/invoices/invoice-record-table/invoice-record-table.component";
import { InvoiceDetailsComponent } from "./components/invoices/invoice-details/invoice-details.component";
import { InvoiceVideoComponent } from "./components/invoices/invoice-video/invoice-video.component";
import { ActsComponent } from "./components/acts/acts.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MedicalRecordsComponent } from "./components/medical-records/medical-records.component";
import { PatientDetailsComponent } from "./components/medical-records/patient-details/patient-details.component";
import { CommentDetailsComponent } from "./components/comments/comment-details/comment-details.component";
import { PrescriptionDetailsComponent } from "./views/prescriptions/prescription-details/prescription-details.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { InboxComponent } from "./components/inbox/inbox.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { EmailComponent } from "./components/email/email.component";
import { PartnersComponent } from "./components/partners/partners.component";
import { AvailabilitiesEditComponent } from "./components/availabilities-edit/availabilities-edit.component";
import { AvailabilitiesNewPlannerComponent } from "./components/availabilities-new-planner/availabilities-new-planner.component";
import { AttachmentSharingsListComponent } from "./components/attachment-sharings-list/attachment-sharings-list.component";
import { AssistantsComponent } from "./components/assistants/assistants.component";
import { MassSmsComponent } from "./components/mass-sms/mass-sms.component";
import { PaymentSmsComponent } from "./components/payment-sms/payment-sms.component";
import { AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import preventAdminMiddleware from "./middlewares/prevent-admin";

// import { PaymentComponent } from './components/payment/payment.component'
import { Router } from "@angular/router";
import { PremiumDoctorGuard } from "./guards/premium-doctor.guard";
import { PaymentComponent } from "./components/payment/payment.component";
import { PremiumConnectPlusDoctorGuard } from "./guards/premium-connect-plus-doctor.guard";
import { PaymentXComponent } from "./components/payment-x/payment-x.component";
import { PaymentGuard } from "./guards/payment.guard";

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      { path: "", component: DashboardComponent, canActivate: [PremiumDoctorGuard] },
      { path: "medical-records", component: MedicalRecordsComponent , canActivate: [preventAdminMiddleware, PremiumConnectPlusDoctorGuard] },
      { path: "templates", component: TemplateComponent, canActivate: [PremiumDoctorGuard] },
      { path: "medical-records/:id", component: PatientDetailsComponent, canActivate: [preventAdminMiddleware, PremiumDoctorGuard] },
      { path: "medical-note", component: MedicalRecordPrintComponent, canActivate: [preventAdminMiddleware, PremiumDoctorGuard] },
      { path: "comments/:id", component: CommentDetailsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "booking-availabilities/types", component: BookingAvailabilityComponent, canActivate: [PremiumDoctorGuard] },
      { path: "profile/edit", component: EditProfileComponent, canActivate: [PremiumDoctorGuard] },
      { path: "booking-availabilities/new", component: DoctorAvailabilitiesNewComponent, canActivate: [PremiumDoctorGuard] },
      { path: "booking-availabilities/edit", component: AvailabilitiesEditComponent, canActivate: [PremiumDoctorGuard] },
      { path: "booking-availabilities/new-planner", component: AvailabilitiesNewPlannerComponent, canActivate: [PremiumDoctorGuard] },
      { path: "patient-labels", component: PatientLabelComponent, canActivate: [PremiumDoctorGuard] },
      { path: "statistics", component: StatisticsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "appointments-list", component: AppointmentsTableComponent, canActivate: [PremiumDoctorGuard] },
      { path: "inbox", component: InboxComponent, canActivate: [PremiumDoctorGuard] },
      { path: "comments", component: DoctorCommentsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "sms", component: SmsDetailsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "prescriptions/header", component: DoctorPrescriptionHeaderComponent, canActivate: [PremiumDoctorGuard] },
      { path: "settings", component: DoctorSettingsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "email", component: EmailComponent, canActivate: [PremiumDoctorGuard] },
      { path: "calendar", component: CalendarComponent, canActivate: [PremiumDoctorGuard] },
      { path: "prescriptions", component: PrescriptionTableComponent, canActivate: [PremiumDoctorGuard] },
      { path: "prescriptions/:id", component: PrescriptionDetailsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "acts", component: ActsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "invoices", component: InvoiceRecordTableComponent, canActivate: [PremiumDoctorGuard] },
      { path: "invoices/video", component: InvoiceVideoComponent, canActivate: [PremiumDoctorGuard] },
      { path: "invoices/:id", component: InvoiceDetailsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "assistants", component: AssistantsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "docshare", component: AttachmentSharingsListComponent, canActivate: [PremiumDoctorGuard] },
      { path: "partners", component: PartnersComponent, canActivate: [PremiumDoctorGuard] },
      { path: "mass-sms", component: MassSmsComponent, canActivate: [PremiumDoctorGuard] },
      { path: "payment-i", component: PaymentXComponent, canActivate: [PaymentGuard] },
      { path: "payment-sms", component: PaymentSmsComponent },
      { path: 'payment', component: PaymentComponent, canActivate: [PaymentGuard] }
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isUserLoggedIn: boolean = false;
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate([""]);
    };
  }
}
