import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { SettingsService } from "src/app/services/settings.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Settings } from "src/app/models/settings";
import { Doctor } from "src/app/models/doctor";
import { SettingsEnum } from "src/app/enums/settings.enum";
import { DoctorService } from "src/app/services/doctor.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { VideoConsultationModalComponent } from "../video-consultation-modal/video-consultation-modal.component";
import { Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Speciality } from "src/app/models/speciality";
import { environment as appConfig } from "../../../environments/environment";
import Swal from 'sweetalert2';

@Component({
  selector: "app-doctor-settings",
  templateUrl: "./doctor-settings.component.html",
  styleUrls: ["./doctor-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DoctorSettingsComponent implements OnInit {
  doctorSettings = {} as Settings;
  current_doctor: Doctor;
  googleToken: boolean;
  selected_minimum_schedule_notice: number;
  selected_minimum_schedule_notice_vc: number; //video
  specialities: Array<Speciality> = [];
  ordered_specialities_ids: Array<String> = [];
  domaineName = appConfig.api_base_url;
  types = [
    { time_type: "minutes", value: "0.5" },
    { time_type: "hours", value: "1" },
    { time_type: "hours", value: "2" },
    { time_type: "hours", value: "3" },
    { time_type: "hours", value: "4" },
    { time_type: "hours", value: "6" },
    { time_type: "hours", value: "12" },
    { time_type: "hours", value: "24" },
    { time_type: "hours", value: "48" },
  ];
  selected_payment_method: string;
  selected: any;

  constructor(
    private settingsService: SettingsService,
    private translate: TranslateService,
    private snackbar: SnackBarService,
    private router: Router,
    private matDialog: MatDialog,
    private doctorService: DoctorService,
    private errorHandler: ErrorsHandler
  ) {}

  ngOnInit() {
    this.getDoctorInfo();
  }

  getSettings() {
    this.settingsService.get().subscribe(
      (data: Settings) => {
        this.doctorSettings = data;
        this.selected_minimum_schedule_notice = data.minimum_schedule_notice;
        this.selected_minimum_schedule_notice_vc =
          data.minimum_schedule_notice_vc;
      },
      (error) => this.snackbar.open(error.message)
    );
  }

  getDoctorInfo() {
    this.doctorService.get().subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.current_doctor = currentDoctor;
        this.googleToken = this.current_doctor.google_token;
        this.getSettings();
        this.specialities = this.current_doctor.specialities;
        if (currentDoctor.ordered_specialities_ids != null) {
          this.InitSpecialitiesOrder();
        }
      }
    });
  }
  InitSpecialitiesOrder() {
    Object.entries(this.current_doctor.ordered_specialities_ids).forEach(
      (ordered_speciality: any) => {
        this.specialities.forEach((speciality) => {
          if (speciality.id == ordered_speciality[0]) {
            speciality.order = parseInt(ordered_speciality[1]);
          }
        });
      }
    );
    this.specialities?.sort((a, b) => (a.order > b.order ? 1 : -1));
    this.current_doctor.specialities = this.specialities;
  }

  updateSettings() {
    this.settingsService.update(this.doctorSettings).subscribe(
      (data) => {},
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  onValChange(event) {
    this.doctorSettings.bookings = event;
    this.updateSettings();
  }
  // Drag and Drop specialities event
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.specialities, event.previousIndex, event.currentIndex);

    //Get all ids of specialities in the new order
    this.specialities.forEach((speciality, index) => {
      this.ordered_specialities_ids.push(speciality.id);
    });

    //subscribe with the list of ordered specialitis_Ids (to be sent to rails api v3 => update_specialities_order )
    this.doctorService
      .updateSpecialitiesOrder(this.ordered_specialities_ids)
      .subscribe(
        (data :any) => {
          this.current_doctor.ordered_specialities_ids = data;
          //Reset the list of Ids
          this.ordered_specialities_ids = [];
          //Get the new doctor data
          this.getDoctorInfo();
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
  }
  onChangeEvent(type, event) {
    this.doctorSettings[type] = typeof event === 'object' ? event.checked : event;
    if (!event.checked)
    {
      this.updateSettings();
      this.getDoctorInfo();
    }
    else
    {
      if (event && type == SettingsEnum.VideoConsultation) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component";
        dialogConfig.height = "300px";
        dialogConfig.width = "400px";
        dialogConfig.data = this.current_doctor;
        this.matDialog
          .open(VideoConsultationModalComponent, dialogConfig)
          .afterClosed()
          .subscribe(() => {
            this.updateSettings();
            this.getDoctorInfo();
          });
      } else {
        this.updateSettings();
      }  
    }
    
  }

  revokeGoogleSync(event: Event) {
    event.preventDefault();
    this.doctorService.revokeGoogleSync().subscribe({
      next: () => {
        this.googleToken = null;
        this.doctorSettings.calendar_sync = false;
        this.snackbar.open(this.translate.instant('connect.doctor_settings.calendar_revoke_success'));
      },
      error: (err) => {
        this.snackbar.open(this.translate.instant('connect.doctor_settings.calendar_revoke_error'));
      }
    });
  }

  confirmRevoke(event: Event) {
    event.preventDefault();
    Swal.fire({
      title: this.translate.instant('connect.doctor_settings.confirm_revoke_sync'),
      icon: 'warning',
      showCancelButton: true, 
      confirmButtonText: this.translate.instant('connect.doctor_settings.yes'),
      cancelButtonText: this.translate.instant('connect.doctor_settings.cancel'),
      html: '<a href="https://myaccount.google.com/connections">'+this.translate.instant('connect.doctor_settings.revoke_sync_link')+'</a>'

    }).then((result) => {
      if (result.value) {
        this.revokeGoogleSync(event);
      }
    });
  }
  
  navigateUrl(url) {
    return this.router.navigate(["/" + url]);
  }
}
