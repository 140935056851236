<div class="appointments-dashboard" data-ktportlet="true" id="kt_portlet_tools_1">
  <header class="dashboard-header">
    <div class="header-content">
      <h2 class="title">
        {{ "connect.calendar.appointment_confirm" | translate }}
      </h2>
      <div class="appointment-counter">
        <span class="counter">{{ toConfirmBookingSlots.length }}</span>
        <span class="counter-label">{{ "connect.calendar.pending" | translate }}</span>
      </div>
    </div>
  </header>

  <div class="appointments-container" kt-hidden-height="259">
    <div class="appointments-list kt-notification-v2" *ngIf="toConfirmBookingSlots.length !== 0">
      <div class="appointment-card" *ngFor="let bookingSlot of toConfirmBookingSlots">
        <div class="card-content">
          <div class="patient-info">
            <h3 class="patient-name">
              {{ bookingSlot.title }}
            </h3>
            
            <div class="appointment-time">
              <i class="far fa-clock"></i>
              {{ bookingSlot.starts_at | customDate }}, {{ bookingSlot.starts_at | customTime }} - {{ bookingSlot.ends_at | customTime }}
            </div>
          </div>

          <div class="contact-info">
            <a class="contact-link email" href="mailto:{{ bookingSlot.user_email }}">
              <i class="far fa-envelope"></i>
              {{ bookingSlot.user_email }}
            </a>
            <a class="contact-link phone" href="tel:{{ bookingSlot.user_phone_number }}">
              <i class="fas fa-phone"></i>
              {{ bookingSlot.user_phone_number }}
            </a>
          </div>

          <div class="request-info">
            <span class="request-time">
              {{ "connect.calendar.requested" | translate }}
              {{ bookingSlot.created_at | timeAgo : currentDoctor.locale }}
            </span>
          </div>

          <div class="tags-container">
            <div class="primary-tags">
              <span class="tag type" *ngIf="bookingSlot.type === bookingTypes.OFFICE">
                {{ "connect.appointment_table." + bookingTypes.OFFICE | translate }}
              </span>
              <span class="tag type" *ngIf="bookingSlot.type === bookingTypes.HOME">
                {{ "connect.appointment_table." + bookingTypes.HOME | translate }}
              </span>
              <span class="tag department" *ngIf="bookingSlot.department">
                {{bookingSlot.department?.name}}
              </span>
              <ng-container *ngFor="let type of doctorBookingTypes">
                <span class="tag booking-type" 
                      *ngIf="type.id === bookingSlot.booking_slot_type_id"
                      [style.background-color]="type.color">
                  {{ type.name | titlecase }}
                </span>
              </ng-container>
              <ng-container *ngIf="bookingSlot.resourceId && bookingSlot.resource">
                <hr class="visits-divider">
                <span class="tag resource">
                  {{ bookingSlot.resource }}
                </span>
              </ng-container>
            </div>

            
            <ng-container *ngIf="bookingSlot.metadata?.confirmed_bookings_count >= 0">
              <hr class="visits-divider">
              <span class="tag visits" [class.first-visit]="bookingSlot.metadata?.confirmed_bookings_count == 0">
                <ng-container *ngIf="bookingSlot.metadata?.confirmed_bookings_count == 0">
                  {{ "connect.calendar.first_time_appointment" | translate }}
                </ng-container>
                <ng-container *ngIf="bookingSlot.metadata?.confirmed_bookings_count > 0">
                  {{ "connect.calendar.total_number_of_appointments" | translate }}: 
                  {{ bookingSlot.metadata.confirmed_bookings_count }}
                </ng-container>
              </span>
            </ng-container>
          </div>
        </div>

        <div class="card-actions">
          <button type="button" class="action-btn confirm" (click)="confirmBookingSlot(bookingSlot)">
            <i class="fas fa-check"></i>
          </button>
          <button type="button" class="action-btn decline" (click)="cancelBookingSlot(bookingSlot)">
            <i class="fas fa-times"></i>
          </button>
          <button type="button" class="action-btn edit" (click)="editBookingSlot(bookingSlot)">
            <i class="fas fa-edit"></i>
          </button>
          <a class="action-btn call" [href]="'tel:' + bookingSlot.user_phone_number">
            <i class="fas fa-phone"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="empty-state kt-notification-v2" *ngIf="toConfirmBookingSlots.length === 0">
      <i class="far fa-calendar-check empty-icon"></i>
      <p>{{ "connect.calendar.appointment_request" | translate }}</p>
    </div>
  </div>
</div>