// payment.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { DoctorService } from '../services/doctor.service';

@Injectable({ providedIn: 'root' })
export class PaymentGuard implements CanActivate {
  constructor(private doctorService: DoctorService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.doctorService.currentDoctor$.pipe(
      filter(doctor => !!doctor),
      take(1),
      map(doctor => {
        const isMorocco = doctor.country == 'MA';
        const correctUrl = isMorocco ? '/payment' : '/payment-i';

        if (state.url == correctUrl)
        {
          return true;
        }
        else
        {
          this.router.navigate([correctUrl]);
          return false;
        }
      })
    );
  }
}
