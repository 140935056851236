<div
  class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor booking-calendar-main"
  (window:resize)="onResize()"
>
  <div class="row mr-lg-2">
    <div class="row w-full">
      <div
        class="col-lg-3 order-2 order-md-1 order-lg-1"
        *ngIf="calendarConfig"
      >
        <mat-calendar
          [selected]="selectedDate"
          [startAt]="startAt"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dateFilter]="myDateFilter"
          (selectedChange)="onSelect($event)"
        >
        </mat-calendar>

        <app-appointment-card
          [toConfirmBookingSlots]="toConfirmBookingSlots"
          [currentDoctor]="currentDoctor"
          [doctorBookingTypes]="doctorBookingTypes"
          (onConfirm)="confirmBookingSlot($event)"
          (onCancel)="cancelBookingSlot($event)"
          (onEdit)="editBookingSlot($event)"
         ></app-appointment-card>

        <app-appointment-categories-card
          [isPortletCollapse]="isPortletCollapse"
          [arrowDirection]="arrowDirection"
          [doctorBookingTypes]="doctorBookingTypes"
          (onNavigateLink)="navigateToLink($event)"
          (onTogglePortlet)="togglePortlet($event)"
        ></app-appointment-categories-card>

        <div
          [ngClass]="isPortletCollapse === true ? 'kt-portlet--collapse' : ''"
          class="appointment-categories kt-portlet"
        >
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ "connect.calendar.settings" | translate }}
              </h3>
            </div>
          </div>
          <div class="kt-portlet__body mt-2">
            <div class="mt-2 settings-left">
              <div class="row">
                <div class="col-7">
                  <span class="zoom-lable p-2">{{
                    "connect.calendar.intervals" | translate
                  }}</span>
                </div>
                <div class="col-5">
                  <div class="selection-row">
                    <mat-select
                      class="full-width"
                      placeholder="{{
                        'connect.calendar.select_option' | translate
                      }}"
                      (selectionChange)="changeSlotDuration($event.value)"
                    >
                      <mat-option
                        *ngFor="let interval of timeIntervals"
                        value="{{ interval.value }}"
                        >{{ interval.viewValue }}</mat-option
                      >
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 settings-left">
              <span class="zoom-lable p-2">{{
                "connect.calendar.set_time" | translate
              }}</span>
              <div class="time-calendar-content mt-3">
                <div class="new-div">
                  <div class="timepickerbox smallinput">
                    <span class="zoom-lable p-2">{{
                      "connect.calendar.start_time" | translate
                    }}</span>
                    <select class="form-control" [(ngModel)]="minTime">
                      <option
                        *ngFor="let hour of startTimeHours"
                        [selected]="minTime == hour"
                      >
                        {{ hour }}
                      </option>
                    </select>
                  </div>
                  <div class="seprator-line">-</div>
                  <div class="timepickerbox smallinput">
                    <span class="zoom-lable p-2">{{
                      "connect.calendar.end_time" | translate
                    }}</span>
                    <select class="form-control" [(ngModel)]="maxTime">
                      <option
                        [value]="hour"
                        *ngFor="let hour of endTimeHours"
                        [selected]="maxTime == hour"
                      >
                        {{ hour }}
                      </option>
                      <option [value]="24">00</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button class="btn add-btn mt-2" (click)="updateSlotTimes()">
                    {{ "connect.calendar.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 order-1 order-md-2 order-ld-2">
        <div
          class="kt-portlet booking-calendar desktop-calendar"
          id="kt_portlet"
        >
          <div class="kt-portlet__body" *ngIf="calendarConfig">
            <full-calendar
              #calendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              [options]="calendarConfig"
            >
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
