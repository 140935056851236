<div class="container-md py-5">
  <!-- Title -->
  <h1 class="text-center mb-5 blue-text font-weight-bold">{{ "connect.payments.transparent_pricing" | translate}}</h1>

  <form id="paymentForm" action="{{cmis.actionSLK}}" method="post" name="paymentForm" *ngIf="cmis">
    <input name="{{key}}" type="hidden" value="{{cmis.params_hash[key]}}" *ngFor="let key of keys; let i=index">
    <input name="HASH" type="hidden" value="{{cmis.transaction_hash}}">
  </form>

  <form [formGroup]="subscriptionForm" accept-charset="UTF-8" (ngSubmit)="onSubmit()" class="row g-4">
    <!-- Subscription Plans -->
    <div class="col-md-5">
      <div class="card mb-3 rounded-card" [ngClass]="subscriptionForm.get('plan_id')?.value == plan.id ? 'blue-border' : 'gray-border'" *ngFor="let plan of plans; let i = index">
        <label class="card-body mb-0" [for]="'plan' + i" style="cursor: pointer;">
          <div class="form-check d-flex align-items-center">
            <div class="d-flex align-items-center">
              <input
                type="radio"
                [value]="plan.id"
                formControlName="plan_id"
                class="form-check-input"
                [id]="'plan' + i"
              >
              <div class="pl-2">
                <h3 class="mb-0 font-weight-bold-900">{{plan.duration}} {{ "connect.payments.months" | translate }} {{ isConnectPlusPlan(plan) ? "*" : "" }} </h3>
                <div class="font-weight-bold blue-text text-size-larger">Dabadoc {{ getPlanName(plan) }}</div>
              </div>
            </div>
            <div class="border-right mx-3" style="height: 40px;"></div>
            <div class="text-end">
              <h4 class="mb-0 font-weight-bold text-nowrap ft-1-3">{{ getPricePerMonth(plan) }} {{ selectedCurrency }}**</h4>
              <span class="font-weight-normal ft-1-3">{{ "connect.payments.per_month" | translate }} <span *ngIf="plan.discount" class="badge badge-warning rounded-pill ">{{ plan.discount }}%</span></span>
            </div>
          </div>
        </label>
      </div>
      <div class="pt-4">
        <small class="text-muted ft-0-8">
          {{ getDynamicConnectPlusPlansMessage() }}
        </small>
        <br>
        <br>
        <small class="text-muted ft-0-8">
          {{ "connect.payments.payment_constraints_notice" | translate }}
        </small>
      </div>
    </div>

    <!-- Contact Form -->
    <div class="col-md-7 contact">
      <div class="card rounded-card gray-border">
        
        
        
        <div class="card-body">
          <h2 class="card-title mb-1 mt-2 font-weight-bold information ft-1-7">{{ "connect.payments.please_provide_information" | translate }}</h2>
          <div class="row g-3 p-3">
            <!-- Name and First Name - Stack on mobile, side by side on desktop -->
            <div class="col-12 col-md-6 mb-3">
              <label class="form-label font-weight-bold">{{ 'connect.payments.last_name' | translate }}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="last_name">
            </div>
            <div class="col-12 col-md-6 mb-3">
              <label class="form-label font-weight-bold">{{ 'connect.payments.first_name' | translate }}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="first_name">
            </div>
        
            <!-- Email field -->
            <div class="col-12 mb-3">
              <label class="form-label font-weight-bold">{{ 'connect.payments.mail' | translate }}<span class="text-danger">*</span></label>
              <input type="email" class="form-control" formControlName="email">
            </div>
        
            <!-- Phone field with adjusted input group -->
            <div class="col-12 mb-3">
              <label class="form-label font-weight-bold">{{ 'connect.payments.phone_number' | translate }}<span class="text-danger">*</span></label>

                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  class="input-radius"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [selectedCountryISO]="country"
                  [selectFirstCountry]="false"
                  [phoneValidation]="false"
                  customPlaceholder=" "
                  formControlName="phone_number"
                  (keyup)="onPhoneNumberChange()"
                >
                </ngx-intl-tel-input>
                <div *ngIf="subscriptionForm.get('phone_number')?.hasError('required') && subscriptionForm.get('phone_number')?.touched" class="text-danger">
                  {{ "connect.online-booking.phone_number_error" | translate }}
                </div>

            </div>
        
            <!-- Address field -->
            <div class="col-12 mb-3">
              <label class="form-label font-weight-bold">{{ 'connect.payments.address' | translate }}<span class="text-danger">*</span></label>
              <textarea class="form-control" rows="3" formControlName="adresse"></textarea>
            </div>
        
            <!-- Terms checkbox -->
            <div class="col-12 mb-3">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" formControlName="cgv" id="conditions">
                <label class="form-check-label" for="conditions">
                  {{ 'connect.payments.i_have_read_and_i_accept' | translate }} <a href="#" class="text-primary">{{ 'connect.payments.the_general_terms' | translate }}*</a>
                </label>
              </div>
            </div>
        
            <!-- Submit button -->
            <div class="col-12 mb-3">
              <button type="submit" class="btn btn-success w-100 green-bg ft-1-2" [disabled]="!subscriptionForm.valid">
                {{ 'connect.payments.valid' | translate }}
              </button>
            </div>
          </div>
        
          <!-- Payment Security - Responsive layout -->
          <div class="mt-2 text-center">
            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
              <span class="mb-2 mb-sm-0 pr-2">{{ 'connect.payments.secure_payment' | translate }}</span>
              <div class="d-flex align-items-center gap-3">
                <img class="img-fluid pr-1" src="https://cdn-icons-png.flaticon.com/512/5968/5968299.png" alt="Visa" style="max-height: 30px;">
                <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/16174/16174534.png" alt="Mastercard" style="max-height: 30px;">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>

  <!-- Help Section -->
  <div class="blue-bg text-white rounded-card p-4 mt-5">
    <div class="help row d-flex justify-content-between m-0">
        <div class="col-md-4 font-weight-bold ft-1-5 d-flex align-items-center p-0">
            <i class="fa fa-question-circle pr-4 ft-2-6"></i>
            <span class="mb-0">{{ 'connect.payments.need_help' | translate }}</span>
        </div>
        <div class="col-md-4 ft-1-5 text-center">
            <div class="font-weight-normal">+212 60 808 5656</div>
        </div>
        <div class="col-md-4 ft-1-5 text-right">
            <div class="font-weight-normal">contact&#64;dabadoc.com</div>
        </div>
    </div>
  </div>
  
</div>