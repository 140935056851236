import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingType } from 'src/app/enums/bookingType.enum';
import { SlotType } from 'src/app/enums/SlotType.enum';
import { BookingSlot } from 'src/app/models/booking-slot';
import { Doctor } from 'src/app/models/doctor';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss']
})
export class AppointmentCardComponent implements OnInit {

  @Input() toConfirmBookingSlots: BookingSlot[] = [];
  @Input() currentDoctor: Doctor;
  @Input() doctorBookingTypes: SlotType[] = [];

  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();

  bookingTypes = BookingType;

  constructor() { }

  ngOnInit(): void {
  }

  confirmBookingSlot(bookingSlot: any) { 
    this.onConfirm.emit(bookingSlot);
  }

  cancelBookingSlot(bookingSlot: any) {
    this.onCancel.emit(bookingSlot);
  }

  editBookingSlot(bookingSlot: any) {
    this.onEdit.emit(bookingSlot);
  }

}
