import { Injectable } from "@angular/core";
import { HttpWrapper } from "./http-wrapper";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { Doctor } from "src/app/models/doctor";

@Injectable()
export class DoctorService {
  private readonly _currentDoctor = new BehaviorSubject<Doctor>(null);
  readonly currentDoctor$ = this._currentDoctor.asObservable();

  constructor(private http: HttpWrapper) {
    this.get().subscribe((currentDoctor: Doctor) => {
      this._currentDoctor.next(currentDoctor);
    });
  }

  get(): Observable<Doctor> {
    return this.http.get("/");
  }

  getDepartmentAvailableResources(data)
  {
    return this.http.post("/available_clinic_resources", data);
  }

  filterDoctors(filters?: string) {
    return this.http.searchGet("/recherche/autocomplete?" + filters);
  }

  checkEmailAvailable(email?: string) {
    return this.http.get("/check_email?" + email);
  }

  delete() {
    return this.http.get("/logout");
  }

  get current(): Doctor {
    return this._currentDoctor.getValue();
  }

  getBookingsTypes() {
    return this.http.get("/bookings_types");
  }

  getLanguages() {
    return this.http.get("/languages");
  }

  getCities() {
    return this.http.get("/cities");
  }

  update_planner(bookingSlot) {
    return this.http.post("/update_planner", bookingSlot);
  }

  updatePrescriptionTitle(prescription_title) {
    return this.http.post("/update_title", {
      prescription_title: prescription_title,
    });
  }

  updateProfile(profile) {
    return this.http.post("/update_profile", { doctor: profile });
  }

  addMedicine(medicine) {
    return this.http.post("/medicines", { medicine: medicine });
  }

  uploadImages(images) {
    return this.http.post("/upload_images", images);
  }

  updatePhoto(image, file) {
    const data = new FormData();
    data.set("file", file);
    data.set("image", image);
    return this.http.postFile("/update_photo", data);
  }

  deletePhoto(image) {
    return this.http.delete(`/delete_photo/${image}`);
  }
  addProfilePicture(file) {
    const data = new FormData();
    data.set("profile_image", file);
    return this.http.postFile("/add_profile_picture", data);
  }

  updateTimeInterval(time_interval) {
    return this.http.post("/update_time_interval", {
      time_interval: time_interval,
    });
  }

  updateTimeSlots(opening_hour, closing_hour) {
    return this.http.post("/update_time_slots", {
      opening_hour: opening_hour,
      closing_hour: closing_hour,
    });
  }

  update_slot_availabilities(bookingSlot, clinic_resource_id) {
    return this.http.post("/update_planner", {
      booking_slot_availabilities_attributes: bookingSlot,
      clinic_resource_id: clinic_resource_id,
    });
  }

  updateBookingstypes(bookings_types) {
    return this.http.post("/bookings_types", {
      booking_slot_types_attributes: bookings_types,
    });
  }

  updatePatientLabels(patient_labels) {
    return this.http.post("/patient_labels", {
      patient_labels_attributes: patient_labels,
    });
  }

  updateCoordinates(coordinates) {
    return this.http.put("/update_coordinates", { coordinates: coordinates });
  }
  
  updateIsPrescriptionHeaderFooterDisabled(is_prescription_header_footer_disabled) {
    return this.http.put('/update_is_prescription_header_footer_disabled', {is_prescription_header_footer_disabled: is_prescription_header_footer_disabled})
  }

  updateSpecialitiesOrder(specialities) {
    return this.http.put("/update_specialities_order", {
      specialities: specialities,
    });
  }
  revokeGoogleSync(): Observable<any> {
    return this.http.put(`/settings/revoke_google_calendar_sync`, { 
      action_type: 'revoke_google_calendar_sync'
    });
  }
}
