<div class="prescription-container">
  <!-- Add toggle button -->
  <div class="header-toggle" style="display: flex; justify-content: space-between;">
    <mat-slide-toggle [(ngModel)]="showHeaderFooter" (change)="toggleHeaderFooter()">
      {{ "connect.prescriptions.show_header_footer" | translate }}
    </mat-slide-toggle>
    <button mat-raised-button color="primary" (click)="printPage()" style="margin-left: 10px;">
      {{ "connect.prescriptions.print" | translate }}
    </button>
  </div>
  <div class="prescription-content" [ngClass]="{'no-header': !showHeaderFooter}">
    <!-- Header -->
    <div class="prescription-header" *ngIf="showHeaderFooter">
      <div class="header-left">
        <div class="caduceus-symbol" style="display: flex; justify-content: center; align-items: center;">
          <img src="../../../assets/images/caduceus-symbol.jpg" width="100px" alt="Medical Symbol">
        </div>
        <h1 class="prescription-title">{{ "connect.prescriptions.ordonnance" | translate }}</h1>
      </div>
      <div class="header-right" *ngIf="currentDoctor">
        <div class="doctor-info">
          <app-editor [content]="currentDoctor.prescription_title" [disabled]="disabled"></app-editor>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- Patient Information -->
    <div class="patient-info" *ngIf="prescriptionDetail">
      <div class="info-row">
        <div class="patient-name">
          <h2>{{ prescriptionDetail.patient_name }}</h2>
        </div>
        <div class="prescription-date">
          <h2>{{ prescriptionDetail.created_at | date : "dd/MM/yyyy" }}</h2>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- Medications -->
    <div class="medications" *ngIf="prescriptionDetail">
      <div class="medication-item" *ngFor="let posologie of prescriptionDetail.posologies">
        <div class="medication-content">
          <h3 class="medication-name">{{ posologie.medicine_name }}</h3>
          <p class="medication-dosage">{{ posologie.medicine_dosage }}</p>
        </div>
      </div>
    </div>

    <!-- Additional Instructions -->
    <div class="additional-instructions" *ngIf="prescriptionDetail?.dosage">
      <div class="instruction-content">
        <h3 class="instruction-title">
          {{ "connect.prescriptions.other_instructions" | translate }}
        </h3>
        <p class="instruction-text">{{ prescriptionDetail?.dosage }}</p>
      </div>
    </div>

    <div class="divider"></div>
    <div class="prescription-footer">
      <div class="qr-code qrcode-box" *ngIf="currentDoctor.profile_url">
        <div  *ngIf="currentDoctor.profile_url && !showHeaderFooter">
          <qrcode
          [qrdata]="currentDoctor.profile_url"
          [cssClass]="'center'"
          [elementType]="'url'"
          [errorCorrectionLevel]="'M'"
          [width]="80"
        ></qrcode> 
        </div>
      </div>
      <div class="signature-section">
        <div class="signature-box"></div>
        <span class="doctor-name" *ngIf="currentDoctor">
          {{ currentDoctor.title == 'none' ? '' : currentDoctor.title | titlecase }}
          {{ currentDoctor.first_name }} {{ currentDoctor.last_name }}
        </span>
      </div>
    </div>
    <div class="footerinfo" *ngIf="showHeaderFooter">
      <div class="contact-info">
          <div class="address">
              {{ "connect.prescriptions.address" | translate }} : {{ currentDoctor.address }} {{ currentDoctor.address_neighborhood }}
          </div>
          <div class="address" *ngIf="currentDoctor.address_ar || currentDoctor.address_neighborhood_ar">
            {{ currentDoctor.address_ar }} , {{ currentDoctor.address_neighborhood_ar }}
           </div>
          <div class="contact-details">
              <div class="contact-row">
                {{ "connect.prescriptions.tele" | translate }} : {{ currentDoctor.phone_number }}
              </div>
              <div class="contact-row">
                {{ "connect.prescriptions.email" | translate }}  : {{ currentDoctor.email }}
              </div>
          </div>
      </div>
      <qrcode
      [qrdata]="currentDoctor.profile_url"
      [cssClass]="'center'"
      [elementType]="'url'"
      [errorCorrectionLevel]="'M'"
      [width]="80"
    ></qrcode>
  </div>
  </div>
</div>