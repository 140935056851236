import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FullCalendarModule } from "@fullcalendar/angular";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ColorGithubModule } from "ngx-color/github";
import { DragDropModule } from '@angular/cdk/drag-drop';

// Views pages
import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
import { MedicalRecordsComponent } from "src/app/components/medical-records/medical-records.component";
import { PatientDetailsComponent } from "src/app/components/medical-records/patient-details/patient-details.component";
import { CommentDetailsComponent } from "src/app/components/comments/comment-details/comment-details.component";
import { PrescriptionDetailsComponent } from "src/app/views/prescriptions/prescription-details/prescription-details.component";

import { StatisticsComponent } from "src/app/components/statistics/statistics.component";
import { InboxComponent } from "src/app/components/inbox/inbox.component";
import { DoctorAvailabilitiesNewComponent } from "src/app/components/doctor-availabilities-new/doctor-availabilities-new.component";
import { CalendarComponent } from "src/app/components/calendar/calendar.component";
import { InvoicesComponent } from "src/app/views/invoices/invoices.component";
import { PartnersComponent } from "src/app/components/partners/partners.component";
// Components
import { AppComponent } from "src/app/app.component";
import { HeaderComponent } from "src/app/components/header/header.component";
import { BottomNavbarComponent } from "src/app/components/bottom-navbar/bottom-navbar.component";
import { BrandComponent } from "src/app/components/brand/brand.component";
import { AsideComponent } from "src/app/components/aside/aside.component";
import { PortletComponent } from "src/app/components/portlet/portlet.component";
import { TableComponent } from "src/app/components/table/table.component";
import { DoctorInfoComponent } from "src/app/components/card/doctor-info.component";
import { PrintButtonComponent } from "src/app/components/print-button/print-button.component";
import { SearchComponent } from "src/app/components/search/search.component";
import { MedicalRecordTableComponent } from "src/app/components/medical-record-table/medical-record-table.component";
import { SmsDetailsComponent } from "src/app/components/sms-details/sms-details.component";
import { PrescriptionTableComponent } from "src/app/components/prescription-table/prescription-table.component";
import { DoctorPrescriptionHeaderComponent } from "src/app/components/prescription-table/doctor-prescription-header/doctor-prescription-header.component";
import { DoctorSettingsComponent } from "src/app/components/doctor-settings/doctor-settings.component";
import { TemplateComponent } from "src/app/components/template/template.component";
import { BookingAvailabilityComponent } from "src/app/components/booking-availability/booking-availability.component";
import { PatientLabelComponent } from "src/app/components/patient-label/patient-label.component";
import { PatientProfileComponent } from "src/app/components/profile/profile.component";
import { LineTabsComponent } from "src/app/components/line-tabs/line-tabs.component";
import { PrescriptionsComponent } from "src/app/components/prescriptions/prescriptions.component";
import { DoctorCommentsComponent } from "src/app/components/doctor-comments/doctor-comments.component";
import { CommonCommentsDetailsComponent } from "src/app/components/doctor-comments/common-comments-details/common-comments-details.component";
import { EditorComponent } from "src/app/components/editor/editor.component";
import { TimelineComponent } from "src/app/components/timeline/timeline.component";
import { DatePickerComponent } from "src/app/components/datepicker/datepicker.component";
import { HeaderSearchFieldComponent } from "src/app/components/header/header-search-field/header-search-field.component";
import { CommentComponent } from "src/app/components/comment/comment.component";
import { DoctorStatsComponent } from "src/app/components/doctor-stats/doctor-stats.component";
import { InlineEditComponent } from "src/app/components/inline-edit/inline-edit.component";
import { BookingDialogComponent } from "src/app/components/booking-dialog/booking-dialog.component";
import { UppyDialogComponent } from "src/app/components/uppy-dialog/uppy-dialog.component";
import { ComingSoonComponent } from "src/app/components/coming-soon/coming-soon.component";
import { AppointmentsTableComponent } from "src/app/components/appointments-table/appointments-table.component";
import { SpinnerComponent } from "src/app/components/spinner/spinner.component";
import { DateRangePickerComponent } from "src/app/components/daterangepicker/daterangepicker.component";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { DoctorTemplateDialogComponent } from "src/app/components/doctor-template-dialog/doctor-template-dialog.component";
import { ChatComponent } from "src/app/components/chat/chat.component";
import { AssistanceDialogComponent } from "src/app/components/assistance-dialog/assistance-dialog.component";
import { VideoConsultationModalComponent } from "src/app/components/video-consultation-modal/video-consultation-modal.component";
import { MedicalRecordPrintComponent } from "src/app/components/medical-record-print/medical-record-print.component";
import { NoteComponent } from "src/app/components/note/note.component";
import { EditProfileComponent } from "src/app/components/edit-profile/edit-profile.component";
import { PhoneNumberComponent } from "src/app/components/phone-number/phone-number.component";
import { WillBeLateDialogComponent } from "src/app/components/will-be-late-dialog/will-be-late-dialog.component";
import { InvoiceRecordTableComponent } from "src/app/components/invoices/invoice-record-table/invoice-record-table.component";
import { InvoiceDetailsComponent } from "src/app/components/invoices/invoice-details/invoice-details.component";
import { InvoiceVideoComponent } from "src/app/components/invoices/invoice-video/invoice-video.component";
import { ActsComponent } from "src/app/components/acts/acts.component";
import { AttachmentSharingsDialogComponent } from "src/app/components/attachment-sharings-dialog/attachment-sharings-dialog.component";
import { AttachmentSharingsListComponent } from "src/app/components/attachment-sharings-list/attachment-sharings-list.component";
import { AttachmentFilesComponent } from "src/app/components/attachment-files/attachment-files.component";
import { AssistantsComponent } from "src/app/components/assistants/assistants.component";
import { AvailabilitiesEditComponent } from "src/app/components/availabilities-edit/availabilities-edit.component";
import { AvailabilitiesNewPlannerComponent } from "src/app/components/availabilities-new-planner/availabilities-new-planner.component";
import { PatientInformationDialogComponent } from "src/app/components/patient-information-dialog/patient-information-dialog.component";
import { PatientLabelFormDialogComponent } from "src/app/components/patient-label-form-dialog/patient-label-form-dialog.component";
import { MapComponent } from "src/app/components/map/map.component";
import { MassSmsComponent } from "src/app/components/mass-sms/mass-sms.component";
import { PaymentSmsComponent } from "src/app/components/payment-sms/payment-sms.component";

// Router module
import { AppRoutingModule } from "src/app/app-routing.module";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker-moment-adapter';

import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ChatAsideComponent } from "./components/chat/chat-aside/chat-aside.component";
import { HeadersInterceptor } from "./interceptors/headers-interceptor";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { MatGridListModule } from "@angular/material/grid-list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatNativeDateModule } from "@angular/material/core";

import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';

import { QuillModule } from "ngx-quill";

// services
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { DoctorService } from "src/app/services/doctor.service";
import { GlobalService } from "src/app/services/global.service";
import { QRCodeModule } from 'angularx-qrcode';
import { ToAgePipe } from "src/app/pipes/age.pipe";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToTimeFormat } from "src/app/pipes/time.pipe";
import { EmailComponent } from "src/app/components/email/email.component";
import { ToDateFormat } from "src/app/pipes/date.pipe";
import { CdkDetailRowDirective } from "src/app/directives/table-row.directive";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TimeAgoPipe } from "src/app/pipes/time-ago-pipe";
import { of, Observable } from "rxjs";
import { environment as appConfig } from "../environments/environment";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { CustomTimePipe } from "src/app/pipes/custom.timepipe";
import { CustomDatePipe } from "src/app/pipes/custom.datepipe";
import { SentryErrorHandlerService } from "src/app/services/sentry-error-handler.service";
import { GoogleMapsModule } from "@angular/google-maps";
import { AuthGuardService as AuthGuard } from "src/app/services/auth-guard.service";
import { AuthService } from "src/app/services/auth.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppointementCategorieFormDialogComponent } from './components/appointement-categorie-form-dialog/appointement-categorie-form-dialog.component';
import { PaymentComponent } from "./components/payment/payment.component";
import { PaymentXComponent } from './components/payment-x/payment-x.component';
import { AppointmentCardComponent } from './components/appointment-card/appointment-card.component';
import { AppointmentCategoriesCardComponent } from './components/appointment-categories-card/appointment-categories-card.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BottomNavbarComponent,
    BrandComponent,
    DashboardComponent,
    AsideComponent,
    PortletComponent,
    TableComponent,
    PrintButtonComponent,
    MedicalRecordsComponent,
    SearchComponent,
    MedicalRecordTableComponent,
    SmsDetailsComponent,
    PrescriptionTableComponent,
    DoctorPrescriptionHeaderComponent,
    DoctorSettingsComponent,
    TemplateComponent,
    BookingAvailabilityComponent,
    PatientLabelComponent,
    PatientDetailsComponent,
    CommentDetailsComponent,
    PrescriptionDetailsComponent,
    PatientProfileComponent,
    LineTabsComponent,
    NoteComponent,
    EditProfileComponent,
    PhoneNumberComponent,
    InvoiceRecordTableComponent,
    InvoiceDetailsComponent,
    InvoiceVideoComponent,
    PrescriptionsComponent,
    DoctorCommentsComponent,
    CommonCommentsDetailsComponent,
    EditorComponent,
    TimelineComponent,
    DialogComponent,
    AttachmentSharingsDialogComponent,
    AttachmentSharingsListComponent,
    AttachmentFilesComponent,
    AssistantsComponent,
    WillBeLateDialogComponent,
    DoctorAvailabilitiesNewComponent,
    DoctorTemplateDialogComponent,
    DatePickerComponent,
    StatisticsComponent,
    HeaderSearchFieldComponent,
    DateRangePickerComponent,
    InboxComponent,
    CommentComponent,
    ChatComponent,
    ChatAsideComponent,
    AssistanceDialogComponent,
    MedicalRecordPrintComponent,
    DoctorInfoComponent,
    DoctorStatsComponent,
    CalendarComponent,
    InlineEditComponent,
    ToAgePipe,
    BookingDialogComponent,
    VideoConsultationModalComponent,
    UppyDialogComponent,
    ToTimeFormat,
    ToDateFormat,
    CustomTimePipe,
    CustomDatePipe,
    EmailComponent,
    ActsComponent,
    InvoicesComponent,
    PartnersComponent,
    ComingSoonComponent,
    CdkDetailRowDirective,
    AppointmentsTableComponent,
    SpinnerComponent,
    TimeAgoPipe,
    AvailabilitiesEditComponent,
    AvailabilitiesNewPlannerComponent,
    PatientInformationDialogComponent,
    PatientLabelFormDialogComponent,
    MapComponent,
    MassSmsComponent,
    PaymentSmsComponent,
    PaymentComponent,
    AppointementCategorieFormDialogComponent,
    PaymentXComponent,
    AppointmentCardComponent,
    AppointmentCategoriesCardComponent,
  ],
  imports: [
    MatToolbarModule,
    GoogleMapsModule,
    CommonModule,
    ColorGithubModule,
    BrowserModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatIconModule,
    AppRoutingModule,
    MatCardModule,
    MatChipsModule,
    MatButtonModule,
    MatRippleModule,
    MatRadioModule,
    MatFormFieldModule,
    CKEditorModule,
    InfiniteScrollModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    MatListModule,
    NgxMatSelectSearchModule,
    MatGridListModule,
    MatInputModule,
    BrowserAnimationsModule,
    QuillModule.forRoot(),
    MatMenuModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    FormsModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatSnackBarModule,
    MatTabsModule,
    NgxDaterangepickerMd.forRoot(),
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule, 
    ReactiveFormsModule,
    FullCalendarModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    ServiceWorkerModule.register("ngsw-worker.js"),
    TooltipModule.forRoot(),
    DragDropModule,
  ],
  providers: [
    MatDatepickerModule,
    SnackBarService,
    ErrorsHandler,
    GlobalService,
    DoctorService,
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandlerService },
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeFr, "fr");
  }
}
