import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { DoctorService } from '../services/doctor.service';

@Injectable({ providedIn: 'root' })
export class PremiumGuard implements CanActivate 
{
  constructor(private doctorService: DoctorService, private router: Router) {}

  canActivate() {
    return this.doctorService.currentDoctor$.pipe(
      filter(doctor => !!doctor),
      take(1),
      map(doctor => ({
        doctor,
        conditionResult: this.condition(doctor)
      })),
      map(({ doctor, conditionResult }) =>
      {
        if (!conditionResult) // if doctor is not premium and/or plan type is not CONNECT_PLUS for MedicalRecordsComponent
        {
          if (doctor?.country == 'MA')
          {
            this.router.navigate(['/payment']);
            return false;
          }
          else
          {
            this.router.navigate(['/payment-i']);
            return false;
          }
        }
        return true;
      })
    );
  }

  protected condition(doctor: any): boolean
  {
    return false;
  }
}