<div class="kt-portlet doctor-template">
  <div class="row">
    <div class="col-md-12">
      <div
        class="kt-portlet setting-data kt-portlet--height-fluid booking_availability_wrapper mb-0"
      >
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label w-100">
            <div class="d-flex flex-row justify-content-between align-items-center w-100">
              <h3 class="kt-portlet__head-title card-title">
                {{
                  "connect.booking_availability.appointment_categories"
                  | translate
                }}
              </h3>
              <button mat-flat-button color="primary" (click)="addAppointmentCategory()">{{ 'connect.booking_availability.add_booking_type' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body p-0">
          <table mat-table [dataSource]="doctorBookingTypes" class="appointment-table">
            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.color' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <span
                  class="color-preview"
                  [style.background]="element.color"
                ></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.appointment_label' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="public">
              <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.status' | translate }} </th>
              <td mat-cell *matCellDef="let element">{{element.public ? translate.instant("connect.booking_availability.public") : translate.instant("connect.booking_availability.not_public")}}</td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.price' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{element.price}}</td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.appointment_length' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{element.duration}}</td>
            </ng-container>
              <ng-container matColumnDef="department">
                  <th mat-header-cell *matHeaderCellDef>{{ 'connect.booking_availability.department' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{element.department_name || "-"}} </td>
              </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="cell-end">  </th>
              <td mat-cell *matCellDef="let element" class="cell-end">
                <div class="action-buttons-row">
                  <button mat-stroked-button (click)="editAppointmentCategory(element)">
                    {{ 'connect.booking_availability.edit_booking_type' | translate }}
                  </button>
                  <button mat-stroked-button color="warn" (click)="deleteAppointmentCategory(element)">
                    {{ 'connect.booking_availability.delete' | translate }}
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['color','name','price','duration', 'public', 'department', 'action']"></tr>
            <tr mat-row class="hover-row" *matRowDef="let row; columns: ['color','name','price','duration', 'public', 'department', 'action'];"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
