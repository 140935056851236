import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlotType } from 'src/app/enums/SlotType.enum';

@Component({
  selector: 'app-appointment-categories-card',
  templateUrl: './appointment-categories-card.component.html',
  styleUrls: ['./appointment-categories-card.component.scss']
})
export class AppointmentCategoriesCardComponent implements OnInit {

  @Input() isPortletCollapse: boolean = false;
  @Input() arrowDirection: boolean = true;
  @Input() doctorBookingTypes: SlotType[] = [];

  @Output() onNavigateLink = new EventEmitter<any>();
  @Output() onTogglePortlet = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  navigateToLink(url: string)
  {
    this.onNavigateLink.emit(url);
  }

  togglePortlet(key: string)
  {
    this.onTogglePortlet.emit(key);
  }

}
